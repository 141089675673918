@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {font-family: 'Poppins';font-weight: 400;font-size: 14px;line-height: 24px;-webkit-font-smoothing: antialiased;padding: 0;margin: 0;color: var(--color600);}
a{text-decoration: none; color: var(--secondary); cursor: pointer;;}
a:hover{color: var(--secondary);}
 
.btn{  border: 1px solid transparent; transition: 0.3s;    font-size: 13px; }
.btn-primary{background-color: var(--primary); border-color: var(--primary);}
.btn-primary:hover{background-color: var(--secondary); border-color: var(--secondary);}  
.btn-default{background-color: transparent; border-color: var(--color800); color: var(--color800);}
.btn-default:hover{ background-color: var(--secondary);  border-color: var(--secondary); color: #fff;}
.bg-warning {
  --bs-bg-opacity: 1; 
  background-color: #fff4dc !important;
}
h1, h2, h3, h4, h5 {font-family: 'Poppins';}
h1,h2,h3,h4,h5{margin-bottom: 0;}
h1{color: var(--color900);}
h2{color: var(--color900);}
h3{color: var(--color900);}
h4{color: var(--color900);}
h5{color: var(--color900);}
.card{    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08); border: none;}
:root {
--primary:#434895;
--secondary:#409de2;
--colorwhite:#fff;
--color100:#F9FAFB;
--color200:#F4F6F8;
--color300:#DFE3E8;
--color400:#C4CDD5;
--color500:#919EAB;
--color600:#637381;
--color700:#454F5B;
--color800:#212B36;
--color900:#161C24;
--sidbarbg: #f4f4f5;
--countbg: #F9FAFB;
--bradcrumbbg: #F9FAFB;

}


.form-group {margin-bottom: 1rem;position: relative;}
.form-group label {display: block;margin-bottom: 5px;font-weight: 600;font-size: 14px;color: #484f68;}
.MuiTextField-root {width: 100%;font-size: 13px;}
 .badge{font-size: 13px; padding: 7px 10px ; font-weight: 500; border-radius: 3px;}
 .bg-inverse-success {    background-color: #26af48;
    color: #ffffff !important;}
 .bg-inverse-warning {background-color: #f39c12 !important;color: #fff !important;}
 .bg-inverse-danger {background-color: rgba(242, 17, 54,0.12) !important;color: #e63c3c !important;}
 .bg-inverse-purple {background: rgba(108, 97, 246, .2) !important;color: #6c61f6 !important;} 
 .bg-inverse-white {background: #fff !important;color: #484f68 !important;}
 .bg-inverse-blue {background-color: rgb(117, 197, 255, .2) !important;color: #3a90cf !important;}
 .bg-inverse-info {background-color: rgba(2, 182, 179, 0.12) !important;color: #1db9aa !important;}
 .bg-inverse-gray {background: rgb(181 181 181 / 20%) !important;color: #ababab !important;}
 .bg-inverse-int {background: rgb(54 112 188 / 20%) !important; color: #406095 !important;}
 .form-group {margin-bottom: 1rem;position: relative;}
 .form-group label{font-weight: 600;margin-bottom: 5px;display: block;font-size: 13px; }
 .MuiSelect-select {font-size: 14px !important;}
.MuiInputLabel-root{font-size: 14px !important; font-weight: 500 !important; background-color: #fff !important;  }
.MuiButtonBase-root.MuiMenuItem-root {display: flex;justify-content: flex-start;padding: 6px;padding-left: 16px;padding-right: 16px; font-size: 14px;}
.MuiFormControlLabel-label{font-size: 14px !important;text-transform: capitalize !important;}
.MuiTextField-root {width: 100%;font-size: 13px;}
.rti--container {padding: 16.5px 14px !important;}
.MuiMenuItem-root {
  font-size: 14px !important;
}

input{font-size: 14px;}
textarea{font-size: 14px !important;}
.reactour__helper{font-size: 16px; color: #000 !important;}
.CXorp,.buSaLG {background: #484fbf !important;}
.ebegOC {top: 10px !important;right: 15px !important;}
.scroll-to-top {background-color: #409de2 !important; }
.scroll-to-top svg{ font-size: 14px;width: 20px;height: 20px;} 


.card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  border-color: rgba(222, 226, 230, .5);
}


/*header css start*/ 
header {padding: 5px 15px;width: 100%;transition: 0.5s;background: #f2f4f7;  position: sticky;top: 0;
  z-index: 99; background-color: #fff;    border-bottom: 1px solid #e9e9ef;    } 
header.fixed { position: fixed; top: 0;    z-index: 9999;transition: 0.5s; background-color: #fff;}
.navbar-header {display: flex;justify-content: space-between;align-items: center;margin: 0 auto;padding: 0 0px 0 0;}
.navbar-brand-box a {display: flex;align-items: center;gap: 10px;}
.logo-icon {text-align: center;}
.logo-icon img {height: 30px;margin: 0 auto;text-align: center;}
.border-right {border-right: 1px solid #eee;padding: 0px 10px;}
.icon-lg {font-size: 26px;}
.bars {width: 18px;line-height: 10px;cursor: pointer;display: flex;flex-direction: column;gap: 5px;}
.bars span {background: #484f68;height: 2px;width: 100%;display: inline-block;transition: 0.25s ease-in-out;}
.bars span.middle {width: 80%;}
.head_right_icon a {color: #484f68;display: flex;align-items: center;gap: 5px;}
 .head_right_icon .profile_avtar{background-color: #434895; width: 30px; height: 30px; font-size: 14px;}
/*header css end*/
 
 
/*-----------------
Sidebar css start
-----------------------*/
.sidebar {margin-top: 0;position: fixed;top: 0px;bottom: 0;left: 0;width: 16rem; flex-shrink: 0;box-shadow: 0 4px 23px rgba(0,0,0,.06);border-right: 1px solid #ebeff3;background-color: var(--sidbarbg);z-index: 1001;}
.sidevar-head {padding:10px 15px;}
.sidevar-head  img{height: 30px;}
.logoicon{display: none;}
.mini-sidebar .logo-main{display: none;}
.mini-sidebar .logoicon{display: block;}
.sidebar .sidebar-inner {height: 100%;transition: all 0.2s ease;}
.sidebar-double {display: flex;flex-direction: column;justify-content: space-between;    height:76%;}
/* .logo-icon {text-align: center;padding: 7px 0px;margin: 0 auto;width: 100%;border-bottom: 1px solid #eee;}  */
.logo-icon .logobig {width: 100%;    height: 72px;object-fit: contain;}
.sidebar .sidebar-menu {overflow: auto;padding: 15px;/* margin-top: 5px; */}
.mini-sidebar .sidebar .sidebar-menu{padding: 15px 0px;}
.sidebar .sidebar-menu ul {padding: 0px 0 0px 0;position: relative;}
.sidebar .sidebar-menu ul li {position: relative; margin-bottom: 8px;}
.sidebar .sidebar-menu ul li a {justify-content: flex-start;position: relative;font-size: 14px;height: auto;transition: all 0.2s ease;padding: 10px 15px;display: flex;font-weight: 500;align-items: center; color: var(--color700); }
 .sidebar .sidebar-menu ul li a span {transition: all 0.2s ease;display: inline-block;margin-left: 15px;white-space: nowrap;}
.mini-sidebar .sidebar { width: 60px;}
.mini-sidebar .sidebar .sidebar-menu ul > li > a span {display: none;transition: all 0.2s ease-in-out;opacity: 0;}
.sidebar .sidebar-menu ul li a.active, .sidebar .sidebar-menu ul li a:hover {background: rgba(0,0,0,.05); }
.sidebar .sidebar-menu ul li a svg{font-size: 20px;}
.mini-sidebar  .logo-icon  .iconlogo{display: block; height: 40px; width: 40px; margin: 0 auto; }
.iconlogo{display: none;}
.mini-sidebar  .logo-icon .logobig{display: none;}
.switch-web {display: grid;gap: 15px;grid-template-columns: 1fr 1fr;}
.switch-web a {border: 1px solid #d8d8d8;padding: 10px;border-radius: 10px;}
.switch-logo img {width: 100%;height: 95px;object-fit: scale-down;}
.switch-web a h5 {font-size: 25px;text-align: center;color: #409de2;font-weight: 500;margin-top: 2px;}
.switch-web a:hover {border-color: #020202;}
.profile-slider {width: 350px;}
.slide-body {padding: 15px;}
.profile-header {border-bottom: 1px solid #eee;background: #f9fafc;padding: 30px 15px;}
.profile-header .slide-close{position: absolute; top: 15px; right: 15px;}
.profile-card {text-align: center;}
.image-icon img {width: 100px;height: 100px;border-radius: 50px;margin-bottom: 10px;box-shadow: 0 2px 2px #ddd;border: 3px solid #fff;}
.profile-title h5 {margin: 5px;font-family: latobold, sans-serif;}
.profile-btn{margin-top: 15px; display: flex; gap: 5px;}
.profile-btn .btn, .profile-btn a{flex: 1; width: 100%;}
.image-icon{position: relative;    width: max-content; margin: 0 auto;}
.profile-loader{position: absolute; display: flex; align-items: center; justify-content: center; z-index: 1; cursor: pointer; top: 0; bottom: 0;left: 0;right: 0;}
.thumb-edit { position: absolute;right: 5px; bottom: 5px; z-index: 1; cursor: pointer;}
.thumb-edit  input {opacity: 0;width: 30px;height: 30px;padding: 0;border-radius: 50%;position: absolute;z-index: 1;cursor: pointer;}
.thumb-edit  input + label {width: 30px;height: 30px;padding: 4px;display: flex;align-items: center;justify-content: center;margin-bottom: 0;border-radius: 10px;background: #ffffff;border: 1px solid transparent;box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 15%);border: 1px solid #eeeeee;font-weight: normal;transition: all 0.2s ease-in-out;cursor: pointer;} 
/*-----------------
Sidebar css end
-----------------------*/ 


/*-----------------
All slides css start
-----------------------*/
.profile-slider{width: 350px;}
.slide-body { padding: 15px;}
.overlay3 {height: 100%;width: 0;position: fixed;z-index: 99;top: 0;left: 0;background-color: rgba(0,0,0, 0.2);overflow-y: auto;overflow-x: hidden;text-align: center;opacity: 0;transition: opacity 1s;}
.slide{ height: 100vh; width: 0; position: fixed; z-index: 999; top: 63px; right: 0; background-color: #fff; overflow: hidden; transition: 0.5s; right: 0;box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%);}
.slide-header {background-color: #f2f4f7; padding: 13px 15px; display: flex; align-items: center; justify-content: space-between; position: relative;}
.slide-close { background: #fff;width: 30px;height: 30px;border-radius: 50%;display: flex;align-items: center;justify-content: center;border: 1px solid #eee; color: var(--color800);}

.filter-num { display: flex; align-items: center; gap: 15px; margin-top: 10px;}
.filter-num-full{  margin-top: 10px;}
.filter-num-slide { flex-basis: 60%; flex-shrink: 0; display: flex; gap: 15px; align-items: center;}
.filter-num-title{flex-grow: 1;}
.filter-num-slide  .MuiTextField-root label{font-size: 14px !important;}
.filter-num-title .MuiTypography-root{font-size: 13px; font-weight: bold;  }
/*----------------------
All slides css end
-----------------------*/ 

 

/*table css start*/ 
.not-fount-table{text-align: center;}
.not-fount-center { padding: 70px 0px ;}
.no-data-info__title {margin-bottom: 0px;}
.bigimage img{width: 400px;}
.not-fount-table .btn{font-size: 12px; width: 200px;}
.fond-icon-content{display: flex; flex-direction: column; gap: 10px;}
.found-image img{filter: invert(50%) sepia(91%) saturate(7068%) hue-rotate(214deg) brightness(105%) contrast(98%);}
.tfoot-area {text-align: center;padding: 15px;}  
.web-table thead tr th {font-weight: 600;}
.web-table thead tr th,  .web-table tbody tr td{text-align: left;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-family: 'Poppins'; font-size: 14px;}
.table-pagination p{margin-bottom: 0;}
.table-pagination {border-top: 1px solid #eee;}
.edit_delet_actions{display: flex;gap: 10px;}
.edit_delet_actions a {width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;background: #f9f9f9;border-radius: 50%;  border: 1px solid #e4e4e4; color: var(--color700); font-size: 16px;}
/*table css end*/


/*project structure  css start*/
.top-info-header {background: #5e64cb; border-bottom: 1px solid #f4e4fc;padding: 6px 15px;text-align: center;position: sticky;top: 0;z-index: 999;font-size: 14px;text-transform: capitalize;display: flex;align-items: center;}
.infomessage {margin: 0 auto;}
.top-info-header span {margin: auto;}
.Kyc-indicator .btn {font-size: 12px;padding: 4px 15px;margin-left: 10px;font-weight: normal;transition: 0.3s;}
 .main-content {margin-left:16rem;} 
.mini-sidebar .main-content { margin-left: 60px;}
 .container {max-width: 1680px;}
input {font-size: 14px !important;}
.page-header{border-bottom: 1px solid #e3e3e3;min-height: 45px;max-height: 87px; position: sticky;top: 0px;background: #fdfdff;width: 100%;gap: 20px;z-index: 9;display: flex;align-items: center;justify-content: space-between; padding: 5px 15px;}
 .addcandidate-resume .MuiButtonBase-root.MuiMenuItem-root {padding: 15px 15px;border-bottom: 1px solid #eee;flex-direction: column;
align-items: flex-start;gap: 5px;}.test-edit {display: flex; column-gap: 15px;    align-items: center; }
.test-edit  .edit-job {
width: 25px; align-items: inherit;height: 25px;border: 1px solid #d3d3d3;}
 .bradcrumbs-box {padding: 5px 10px 5px 15px;background: var(--bradcrumbbg);border-bottom: 1px solid #eee; }
.bradcrumbs-box li a , .bradcrumbs-box li p{font-size: 12px;}
.bradcrumbs-box .MuiBreadcrumbs-separator{margin: 0px 4px;}
.addcandidate-resume .candidatelable {text-transform: capitalize;font-weight: bold;}
.rowmargin{margin-top: 20px;}
/*project structure  css end*/


/*project globle css start*/
.page-loader {position: absolute;top: 0;background: #fff;width: 100%;height: 100%;z-index: 99;left: 0;right: 0;margin: 0 auto;display: flex;align-items: center;justify-content: center;}
.inner-loader {display: flex;flex-direction: column;align-items: center;gap: 15px;text-align: center;}
.inner-loader span {display: block;margin-top: 10px; }
.sectionpadding{padding: 100px 0px; overflow: hidden;}
.section-title { margin-bottom:30px;text-align: left;}
.section-title h2 {font-weight: 800;}
 
.card {background-color: #fff;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06);border-radius: 2px; border-color: rgba(222, 226, 230, .5);}
.card-body {padding: 15px;}
.card-item__content {padding-bottom: 14px;display: flex;align-items: center;gap: 20px;justify-content: space-between;}
.card-item__content .btn{ }
.card-title {font-weight: 600;  }
/*noimage */
 .bigimage img {width: 400px;}
 .fond-icon-content span {font-weight: 500; }
 .test-tabs ul{display:flex; align-items: center; gap: 15px; margin: 0; padding: 0;}
.test-tabs ul li{list-style: none;}
.test-tabs ul li a{    font-weight: 600;    border-bottom: 2px solid transparent;padding-bottom: 6px;} 
.test-tabs ul li a.active {border-color: var(--secondary);}

 

/*globle pagination */
.page-title-box {padding: 15px;display: flex;align-items: center;justify-content: space-between;max-width: 1680px;margin: 0 auto;}
.page-title-content {width: 50%;}
.page-title-right {display: flex;gap: 15px;}
 .page-title-box h4{display: flex;align-items: center; gap: 10px; font-weight: 600; font-size: 18px; }
.page-title-box span{display: block; margin-top: 5px; font-size: 13px;}
.page-title-box h4 a{ color: var(--secondary);}
 .page-body {padding: calc(0px + 0px) calc(0px * 0) 0px calc(0px * 0);}
.test-wrapper .tfoot-area { border-top: 1px solid #eee;position: relative; z-index: 9;background-color: #fff;}
.tfoot-area .MuiPaginationItem-root.Mui-selected {background-color: var(--primary) !important;}
 .MuiPagination-ul {justify-content: center;}
 .MuiPagination-root {padding: 15px 0px;}
/*globle editor  */
.demo-wrapper {width: 100% !important;display: block !important;}
.demo-editor {height: auto;overflow: inherit;min-height: 150px;border: 1px solid #c9c9c9 !important;padding: 5px 15px !important;border-radius: 2px !important;word-break: break-word;      background-color: #fcfcfc !important;    }
.rdw-editor-toolbar{  margin-bottom: 0px !important; border-bottom: 0 !important;     border: 1px solid #c9c9c9; }
.MuiTooltip-tooltip{background-color: #fff; text-align: center;  }

/*filter search */ 
.search-filter {flex-shrink: 0;flex-basis: 24%;display: flex;align-items: center;gap: 15px;}
.search-candidate {position: relative;flex-grow: 1;}
.search-candidate-icon {position: absolute;top: 12px;left: 10px;}
.search-candidate-icon svg { color: var(--color700);}
.filter-candidate {position: absolute;top: 16px;right: 10px;}
.filter-candidate a {border: 1px solid #c4c4c4;padding: 5px 15px;border-radius: 7px;  transition: 0.5s;font-weight: 600; color: var(--color700);}
.filter-icon {display: inline;align-items: center;}
.filter-icon svg {margin-right: 3px; }
.search-candidate .searchbarcandidate input {padding-left: 45px;} 
.filter-detaildrawer{width: 500px;}
.filter-head {border-bottom: 1px solid #eee;background: #f9fafc;padding: 15px 15px;display: flex;align-items:center;justify-content: space-between;gap: 6px; font-weight: bold;}
.filter-title{display: flex; align-items: center; gap: 5px;}
.filter-body{padding: 15px; overflow: auto;  max-height: calc(100vh - 100px);}
.filter-result-wrapper{background-color: #fff;   margin-bottom: 15px;    display: flex;align-items: center;gap: 5px;}
.filter-result-row {display: flex;flex-wrap: wrap;gap: 5px; }
.Clear-Filter a {  width: 20px;height: 20px;display: block;display: flex;align-items: center;justify-content: center;background: #ea5355;border-radius: 50%;}
.filter-result-item {display: flex;gap: 5px;align-items: center;background: #eee;padding: 5px 10px;border-radius: 20px;}
.filterlable {font-weight: 500;}
.filter-action a {width: 10px;height: 10px;display: flex; align-items: center;}
.filter-action { display: flex; align-items: center; gap: 10px; border-left: 1px solid #000; padding-left: 5px; margin-left: 5px; height: 100%;}
.filterlable-answer {display: flex;align-items: center;gap: 5px;}
.shortby{margin-right: 15px;}
.shortby a {color: #666;display: flex;align-items: center;gap: 5px;}
.create-menu a {color: #000;display: flex;align-items: center;padding: 10px 25px 10px 15px;}
.create-menu .MuiButtonBase-root.MuiMenuItem-root {padding: 0;}



/*global count*/ 
 
.count-flex{display: flex; gap: 15px;}
.count-cta a {padding: 15px;border: 1px solid #eee;border-radius: 10px;text-align: center;display: flex;flex-direction: column;row-gap: 10px;transition: 0.3s;background: var(--countbg);}
.count-cta a:hover{background-color: var(--primary);}
.count-cta a:hover h2, .count-cta a:hover h5{color: var(--colorwhite);}
.count-cta h2{font-size: 18px; font-weight: 700;}
.count-cta h5 {font-size: 14px;color: var(--color500);}
.colmrg{margin-bottom: 15px;}
/*project globle css end*/

 

/*  popup  css start*/
.commonpopup{position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%); box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; background-color: #fff;  border-radius: 15px;  }
.popup-w35{width: 35%;}
.popup-w50{width: 50%;}
.popup-w75{width: 75%;}
.popup-w85{width: 85%;}
.modal-common  .modal-header {border-bottom: none;text-align: center;display: initial;padding-bottom: 30px;}
.modal-common .icon-box {display:flex; margin: 15px auto 10px auto;left: 0;right: 0;top: -55px;width: 95px;height: 95px;border-radius: 50%;z-index: 9;background: transparent;padding: 15px;text-align: center;border: 1px solid #eee;box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);}
.modal-common .icon-box i { position: relative;top: 3px; }
.icon-box i { width: 55px; height: 55px; line-height: 55px; text-align: center;  } 
.modal-confirm .icon-box i { color: #82ce34;}
.modal-confirm   h4 { color: #82ce34;  font-weight: 800; margin-bottom: 0px;} 
.modal-common .model-content{margin-top: 30px;}
.modal-common  h3 { font-weight: 700; font-size: 18px;    margin-bottom: 10px;}
.modal-title h4 {font-size: 22px;}
.modal-contant { margin-top: 25px;} 
.modal-btn{display: flex; gap: 10px; justify-content: center; margin-top: 30px;}
.modal-btn .btn{flex: 1;}
.modal-header{background: #f1f5f8; position: relative;  padding: 15px;border-bottom: 1px solid #eee;justify-content: center;}
.modal-header h4{font-weight: bold;}
.modal-body{padding: 25px;}
.close {background-color: var(--color300);  color: var(--color800);   border-radius: 50%;   height: 25px;width: 25px;line-height: 20px;margin: 0;opacity: 1;padding: 0;position: absolute;right: 15px;top: 15px;z-index: 99;display: flex;
align-items: center;  justify-content: center;}
.close:hover{color: var(--color800);}
.deactive .icon-box {border: 1px solid #bb2d3b;}
.deactive .icon-box i {color: #bb2d3b;} 
.modal-common .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.see-button {
  padding: 25px 15px 0px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 15px;
}
/*  popup  css end*/


/*category page css start*/
.category-box {
  background: var(--countbg);
  border-radius: 10px;
  border: 1px solid #e3e3e3;
}
.category-card-head{    padding: 15px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;}

  .category-icon {
    width: 50px;
    height: 50px;
    position: relative;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
}

.category-icon img {
  width: 100%;
  
  height: 100%;
 
}
 

 .category-title h6{margin-bottom: 0;}

 
 .add-category-row {
  display: flex;
 flex-direction: column;
 
 
}


.category-icon-left{text-align: center; margin-bottom: 15px;}
.category-icon-left .category-icon {       width: 75px;
  height: 75px;
 }



 
.cat-icon-action {
  display: flex;
  align-items: center;
  gap: 10px;
  
  margin-top: 14px;
}
.cat-icon-add{
   
  z-index: 1;
  cursor: pointer;
}


.cat-icon-add input {
  opacity: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
 
.category-icon-right{flex-grow: 1;}




/*category page css start*/








/*upload video detail page css start*/
.video_setting_flex{display: flex; gap: 30px;} 
.left_setting_section {flex-grow: 1;}
.setting-section-row {padding: 19px 15px;border-bottom: 1px solid #eee;}
.video-setting-head { margin-bottom: 10px;}
.video-setting-head h5 {font-size: 16px;font-weight: 500;}
.video-setting-head span{margin-top: 5px; display: block;}
.video-setting-body{padding: 10px 0px 0px 0px;}
.csv-upload {background-color: #f9f9f9; border: 1px solid #eee;display: block; position: relative; display: flex; align-items: center; justify-content: center;flex-direction: column;  padding: 0px ;    row-gap: 10px;width: 650px;}
.csv-upload h5 {font-size: 15px;margin-bottom: 9px;}
.svg-circle{  width: 100px;height: 100px;background: #fff;border-radius: 50%;    margin-bottom: 15px;  border: 1px solid #eee;padding: 7px; display: flex; align-items: center; justify-content: center;}
.csv-upload svg {font-size: 50px;color: #000000;}
 .invitedevider { margin: 15px 0px;text-align: center;font-weight: bold;}
.csv-upload label { display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;flex-direction: column;cursor: pointer;    padding: 50px 15px;}
.csv-upload input {position: absolute;top: 0;left: 0;right: 0;margin: 0 auto;height: 100%;}
.upload-btn label {padding: 10px 20px;}
.labeltext { margin-bottom: 4px; font-weight: 600; font-size: 14px;} 
.uploaded-resume{display: flex; align-items: center; justify-content: space-between; width: 100%;}
.resume-action{display: flex; gap: 15px;}
.resume-action a {width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;background: #f9f9f9;border-radius: 50%;font-size: 20px;color: #000;border: 1px solid #e4e4e4;}
.thumbnil-flex {display: flex; flex-wrap: wrap;column-gap: 15px;row-gap: 15px;margin-bottom: 15px;}
.thumbnil-box{width: 250px; height: 150px; border: 1px solid #eee;display: flex;align-items: center;    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
justify-content: center; position: relative;}
.thumbnil-box .resume-action {position: absolute;top: 10px;right: 10px;}
.thumbnil-upload span {font-weight: 600;}
.thumbnil-svg-circle {width: 50px;height: 50px;background: #f9f9f9;border-radius: 50%;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
.thumbnil-upload label {text-align: center;display: flex;row-gap: 10px;flex-direction: column;cursor: pointer;}
.uploaded-image{padding: 15px;  }
.uploaded-image img {width: 100px;}
.uploaded-image .resume-action{position: absolute; top: 10px; right: 10px;} 
.Visibility-sec {
  border: 1px solid #222;
  padding: 15px;
  border-radius: 10px;
}
.radio-text {
  padding-bottom: 15px;
}
.video-setting-head span b {
  display: block;
}
.radio-text .shortcontent {
  display: block;
  padding-left: 30px;
}
.radio-text .MuiRadio-root {
  padding: 0px 9px 0px 9px;
}
.radio-text .MuiTypography-root{font-weight: 600;}
.create-course-scroll {
  overflow: auto;
  max-height: calc(100vh - 126px);
}
.submit-section {
  padding: 15px;
  border-top: 1px solid #eee;}

 

/*upload video detail page css end*/


/*course list page css start*/
.cources-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}
.jobcta {padding: 15px;border: 1px solid #eee;border-radius: 20px;background-color: #fbfbff;position: relative;overflow: hidden;z-index: 9;height: 100%;    cursor: pointer; transition: 0.5s;}
.jobcta:hover {background: var(--primary); color: var(--colorwhite);}
.jobcta:hover .course-description-card p , .jobcta:hover .job-title h5  , .jobcta:hover  .prize-box , .jobcta:hover .del , .jobcta:hover  .discount-price h4{color: var(--colorwhite);}
.jobcta:hover  .view-detail .btn{background-color: var(--secondary);}
.jobcta:hover:before{background: #dcdfff; width: 350px;height:350px;}
.jobcta:before {content: '';position: absolute;width: 300px;height: 300px;background: #409de2;border-radius: 50%;z-index: -1;opacity: 0.1;top: -103px;left: -70px; transition: 0.3s;}
.top-head {display: flex;align-items: center;justify-content: space-between;}
.jobicon {width: 80px;height: 80px;background: #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center;border: 1px solid #eee;padding: 8px;}
.jobicon img{width: 100%; height: 100%; border-radius: 50%;}
.job-title{margin: 15px 0px 5px 0px;}
.job-title h5 {font-weight: 600;     line-height: 29px;}
 .course-description-card{overflow: hidden;display: block;max-height: 40px;-webkit-line-clamp: 3;  -webkit-box-orient: vertical;text-overflow: ellipsis;white-space: normal;}
.course-description-card{margin-top: 10px;}
.course-description-card p{font-size: 13px; color: var(--color800);}
.prize-box{height:130px; margin-top: 20px;}
.del{text-decoration: line-through; font-size: 20px; color: var(--color500);}
.discount-price {margin-top: 10px;display: flex;align-items: center;gap: 20px;}
.discount-price h4{font-weight: 600; color: rgb(43, 169, 43); font-size: 22px;}
.view-detail {position: absolute;bottom: 25px;width: 90%;left: 0;right: 0;margin: 0 auto;}
.view-detail .btn {width: 100%; font-size: 14px;}
/*course list page css end*/



/*course detail page css start*/

.bg-banner{background-color: var(--color800);    }

.course-banner-content {
  padding: 50px 0px 50px 33px;
}

.course-banner-content h3 {
color: var(--colorwhite);
margin-bottom: 15px;
line-height: 38px;
}
.course-banner-content h6 {color: var(--color300); margin-bottom: 0; }

.ratting-enroll{margin-top: 15px;}
.rating{display: flex; align-items: center; gap: 10px; color: var(--colorwhite);}
.rating .MuiRating-iconEmpty svg {color: var(--colorwhite);}
.enrollstudents span{    padding: 3px 15px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px; font-weight: 600;}
.ratting-enroll {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
}
.update-course{margin-top: 15px; color: var(--color500);}


.course-content-flex {
  display: flex;
  gap: 25px;
}
.cours-left{flex-grow: 1;}
.course-full-content {padding: 0px 2rem;}
.course-multides-box {
  margin-top: 2rem;
}


.course-multides-head h4{font-weight: 700; }
.course-multides-body{padding: 15px 0px;}

.course-description ul li{margin-bottom: 15px; font-size: 13px;}

.cours-right{width: 400px; flex-shrink: 0;}

.exam-card-box{    box-shadow: 0 25px 50px -12px rgba(0,0,0,.25);
 
  position: sticky;
  top: 99px;
  margin-top: -240px;
  z-index: 999;
  background: #fff;}

  .exam-sliders{height: auto; background-color: var(--color400);}
  .exam-card-body{padding: 15px;}
 
.detail-prize-box .detail-discount-price {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}
.detail-discount-price h4 {
  font-weight: 700;
  font-size: 22px;
}

.bynow{margin-top: 25px; margin-bottom: 15px;}
.bynow .btn{width: 100%; padding: 10px 15px; font-size: 16px;}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {height: 35px;width: 35px;}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg{width: 20px; height: 20px;}
/*course detail page css start*/

/*login page css start*/
.auth-center{height: 100vh; display: flex; flex-direction: column;}
 .google-icon {position: relative;top: 0px;display: inline-block;margin-right: 5px;width: 15px;height: 15px;background: url(../images/google-icon.svg) no-repeat;}
.btn-google{color: #000;   border-radius: 10px;border: 1px solid #c7c8c9;text-align: center;padding: 10px 10px;font-size: 14px;display: flex;align-items: center;justify-content: center;gap: 3px;} 
.form-width {max-width: 530px;width: 100%;margin: 0 auto;}
.login-width{max-width: 430px;width: 100%;margin: 0 auto;} 
.auth-bg {position: relative;}
.auth-bg { height: 100vh;    overflow: hidden;padding: 15px;}
.bg-overlay {position: absolute;height: 100%;width: 100%;right: 0;bottom: 0;left: 0;top: 0;opacity: .7;background-color: rgb(248 248 255);}
.auth-bg .bg-overlay {opacity: .9;}
.bg-bubbles {position: absolute;top: 0;left: 0;width: 100%;height: 100%;overflow: hidden;}
.bg-bubbles li {position: absolute;list-style: none;display: block;width: 40px;height: 40px;border-radius: 30px;     background-color: rgb(238 238 250);bottom: -50px;-webkit-animation: square 20s infinite;animation: square 20s infinite;-webkit-transition-timing-function: linear;transition-timing-function: linear;}
.swiper-slide {font-size: 18px;display: flex;justify-content: center;align-items: center;}
.sliderimage   {position: relative;}
.sliderimage img {width: 100%;}
.bg-bubbles li:nth-child(1) {left: 10%;}
.bg-bubbles li:nth-child(2) {left: 20%;width: 120px;height: 120px;-webkit-animation-delay: 2s;animation-delay: 2s;-webkit-animation-duration: 17s;animation-duration: 17s;}
.bg-bubbles li:nth-child(3) {left: 25%;-webkit-animation-delay: 4s;animation-delay: 4s;}
.bg-bubbles li:nth-child(4) {left: 40%;width: 80px;height: 80px;-webkit-animation-duration: 22s;animation-duration: 22s;}
.bg-bubbles li:nth-child(5) {left: 70%;width: 90px;height: 90px;}
.bg-bubbles li:nth-child(6) {left: 70%;width: 120px; height: 120px;-webkit-animation-delay: 3s;animation-delay: 3s;}
.bg-bubbles li:nth-child(7) {left: 32%;width: 150px;height: 150px;-webkit-animation-delay: 7s;animation-delay: 7s;}
.bg-bubbles li:nth-child(8) {left: 55%; width: 80px;height: 80px;-webkit-animation-delay: 15s;animation-delay: 15s;-webkit-animation-duration: 40s;animation-duration: 40s;}
.bg-bubbles li:nth-child(9) {left: 25%;width: 50px; height: 50px; -webkit-animation-delay: 2s; animation-delay: 2s; -webkit-animation-duration: 40s; animation-duration: 40s;}
.bg-bubbles li:nth-child(10) {left: 90%; width: 140px; height: 140px; -webkit-animation-delay: 11s; animation-delay: 11s;} 
@-webkit-keyframes square {0% {	-webkit-transform: translateY(0);transform: translateY(0)}
100% {-webkit-transform: translateY(-1000px) rotate(600deg);transform: translateY(-1000px) rotate(600deg)}}
@keyframes square {0% {-webkit-transform: translateY(0);transform: translateY(0)}100% {-webkit-transform: translateY(-1000px) rotate(600deg);transform: translateY(-1000px) rotate(600deg)}}
.auth-full-page-content {overflow: auto;height: 100vh; max-height: calc(100vh - 20px);padding: 10px;position: relative;}
.welcometext h5 {font-weight: 600;}
.welcometext p{font-size: 13px;}
.logincorporate label {font-size: 13px; font-weight: 500;}
.logincorporate .form-control {height: 46px;font-size: 13px;border-color: #ced4da;}
.whatsapbtn {text-align: center;}
.whatsapbtn img {width: 300px;margin: 0 auto;}
.notmember p {font-size: 13px; color: #74788d;}
.forgotpass a {font-size: 13px;color: #000;}
.login-box p, .note {font-size: 13px;color: #526484;}
.PhoneInputCountrySelect {position: absolute;top: 0;left: 0;height: 100%;width: 100%;z-index: 1;border: 0;opacity: 0;cursor: pointer;}
.PhoneInput {display: flex;align-items: center;}
.worldwidephone {border: 1px solid #c4c4c4 !important;    height: 38px;border-radius: 5px;}
.PhoneInputCountry{padding: 0px 8px;}
.worldwidephone .PhoneInputInput{flex: 1 1;min-width: 0;font-size: 13px;letter-spacing: inherit;color: currentColor;padding: 4px 0 5px;border: 0;box-sizing: content-box;background: none;height: 1.4375em;margin: 0;-webkit-tap-highlight-color: transparent;display: block;min-width: 0;width: 100%;-webkit-animation-name: mui-auto-fill-cancel;animation-name: mui-auto-fill-cancel;-webkit-animation-duration: 10ms;animation-duration: 10ms;padding: 7px 4px;border-left: 1px solid #c4c4c4;}
.alert-message {display: flex;align-items: center;gap: 10px;}
.otp-alt {padding: 6px 15px;margin-bottom: 10px;}
.alert-message svg{font-size: 18px;}
.alert-message  p{font-size: 12px;}
.error{font-size: 12px;} 
.logincorporate .btn{    font-size: 16px;padding: 8px 0px;font-weight: 500;}
.other-login-options {display: grid;grid-template-columns:1fr;gap: 10px;margin: 25px 0px 15px 0px;}
.btn-google {color: #000;border-radius: 10px;border: 1px solid #c7c8c9;text-align: center;padding: 10px 10px;font-size: 14px;display: flex;
align-items: center;justify-content: center;gap: 10px;}
.btn-google img {width: 20px;height: 20px;}
.sliderimage{position: relative;}

 
/*login page css end*/ 